<!--
 * @version: 1.0
 * @Date: 2023-04-25 17:06:58
 * @LastEditors: cyx 
-->
<template>
  <div class="form-config-wrapper" v-if="formState.type !== 'SEAL_INFO'">
    <a-form layout="vertical" :rules="rules" ref="formRef" :model="formState">
      <a-form-item :label="`控件名称${formState.controlName === 'DateTimeRange' ? '1' : ''}`" name="title">
        <a-input
          v-model:value="formState.title"
          placeholder="请输入"
          :maxLength="6"
          :disabled="allDisabled ? allDisabled : formState.props.disabledTitle"
        />
      </a-form-item>
      <a-form-item
        label="占位符"
        name="placeholder"
        v-if="
          !(
            formState.controlName === 'FileUpload' ||
            formState.controlName === 'DateTimeRange' ||
            formState.type === 'TAKE_OUT'
          )
        "
      >
        <a-input
          v-model:value="formState.props.placeholder"
          placeholder="请输入"
          :maxLength="14"
          :disabled="allDisabled"
        />
      </a-form-item>
      <component :is="components[`${formState.configName}`]" :config="formState.props" />
      <!-- <component :is="components[formState.configName]" :config="formState.props" /> -->

      <!-- 外带模块独有 - 是否支持多个外地地址 -->
      <a-form-item label="是否支持多个外带地址" v-if="formState.type === 'TAKE_OUT'">
        <a-switch
          :checked="basicForm.isMultipleAddress || formState.props.isMultipleAddress"
          @change="changAddress"
          :disabled="allDisabled || !formState.props.showTakeOut"
        />
      </a-form-item>
      <a-form-item label="提示文字" name="tipsText" v-if="formState.type !== 'REMOTE'">
        <a-input
          v-model:value="formState.props.tipsText"
          placeholder="请填写"
          :maxLength="30"
          :disabled="allDisabled"
        />
      </a-form-item>
      <!-- v-if="formState.type !== 'TAKE_OUT'" -->
      <a-form-item label name="required">
        <a-checkbox
          v-model:checked="formState.props.required"
          :disabled="allDisabled ? allDisabled : formState.props.disabledRequired"
          @click.prevent="clickCheck(formState.id, store.state.process.design.process)"
          >这是一个必填项</a-checkbox
        >
      </a-form-item>
    </a-form>
    <!-- 只有盖印方式控件显示ocr对比  start-->
    <div v-if="formState.type === 'REMOTE'" class="ocr-container">
      <div class="title">其他可选</div>
      <a-row>
        <a-col>OCR文件比对：</a-col>
        <a-col>
          <a-switch :checked="basicForm.ocrType === 1" @change="changOcr" :disabled="allDisabled" />
        </a-col>
      </a-row>
      <div class="explain" v-if="basicForm.ocrType === 1">
        开启后，盖印人需在用印前进行文件OCR比对，比对通过才可进行盖印操作
      </div>
      <a-radio-group
        v-model:value="basicForm.ocrPattern"
        @change="changeQrcodePageType"
        v-if="basicForm.ocrType === 1"
        :disabled="allDisabled"
      >
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="1"
          >整份文件拍摄盖印</a-radio
        >
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="2"
          :disabled="disabledQrcodeType?.disabledPage"
          >单页拍摄盖印</a-radio
        >
      </a-radio-group>
      <div class="match-ratio" v-if="basicForm.ocrType === 1">
        文件比对匹配率达到
        <a-input style="width:80px" v-model:value="basicForm.ocrDegree" @input="checkMate" :disabled="allDisabled" />%
      </div>
      <a-radio-group v-model:value="basicForm.ocrStrategy" v-if="basicForm.ocrType === 1" :disabled="allDisabled">
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="1"
          >匹配率不通过，不允许盖印</a-radio
        >
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="2"
          >匹配率不通过，允许盖印</a-radio
        >
      </a-radio-group>
      <div v-if="basicForm.ocrType === 1">
        <span>允许重新比对次数</span>
        <a-select
          :disabled="allDisabled"
          v-model:value="basicForm.ocrRetriesNumber"
          :style="{ width: '74px', margin: '16px 8px 0 8px' }"
          :options="options"
          :getPopupContainer="trigger => trigger.parentElement"
        ></a-select>
        <span>次</span>
      </div>
      <!-- 二维码水印配置 -->
      <a-row class="margintop_24">
        <a-col>二维码水印：</a-col>
        <a-col>
          <a-switch :checked="basicForm.qrcodeType === 1" @change="changQRcodeType" :disabled="allDisabled" />
          <a-tooltip placement="top" class="tips-text" overlayClassName="qrcode-tip-overlay">
            <template #title>
              <div style="display:block;font-size: 14px;">
                <div>常规盖印支持二维码水印：单文件/单页模式；</div>
                <div>连续盖印仅支持二维码水印：单文件模式；</div>
                <div>远程盖印不支持二维码水印；</div>
              </div>
            </template>
            <QuestionCircleFilled class="charts-icon" style="margin-left: 8px" />
          </a-tooltip>
        </a-col>
      </a-row>
      <a-radio-group
        v-model:value="basicForm.qrcodePageType"
        @change="changeQrcodePageType"
        v-if="basicForm.qrcodeType === 1"
        :disabled="allDisabled"
      >
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="1"
          >单文件</a-radio
        >
        <a-radio
          :style="{
            display: 'flex',
            height: '30px',
            alignItems: 'center'
          }"
          :value="2"
          :disabled="disabledQrcodeType?.disabledPage"
          >单页</a-radio
        >
      </a-radio-group>
    </div>
    <!-- 只有盖印方式控件显示ocr对比  end-->
  </div>
  <div v-else>
    <SealInfo :config="formState.props" :allDisabled="allDisabled" />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onUpdated, defineProps, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import components from '../configExport'
import SealInfo from '../config/SealInfoConfig.vue'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined, QuestionCircleFilled } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { getConfigure, getOcrJurisdiction } from '@/apis/businessManage'
import { cmsNotice } from '@/utils/utils'
import { notification } from 'ant-design-vue'

const props = defineProps({
  //操作日志使用组件禁用只能预览
  allDisabled: {
    type: Boolean,
    default: false
  }
})

const state = reactive({
  openModelList: []
})
onUpdated(() => {
  console.log(88881, formState.value.props.isMultipleAddress, basicForm.value)
  if (formState.value.configName === 'SealInfo') return
  formRef.value.validateField(['title'], err => {
    console.log(err)
  })
})
let conditionsList = []
let currentFieldId = 0
let cidsList = []

async function changOcr(value) {
  if (value) {
    // 调用接口检查是否开通ocr
    // 开通的打开用印前拍照
    // 没开通提示开通
    const res = await getOcrJurisdiction()
    if (res.data === 2) {
      cmsNotice('error', '如需开启OCR比对功能请联系客服')
      return
    }
    if (state.openModelList.length == 1 && state.openModelList.includes('远程盖印')) {
      return cmsNotice('warning', '远程盖印不支持OCR文件比对')
    }
    //二维码水印打开关闭OCR，二者存其一
    basicForm.value.qrcodeType = 2
    basicForm.value.ocrType = 1
    basicForm.value.ocrDegree = 80
    basicForm.value.ocrStrategy = 1
    basicForm.value.ocrPattern = 1
    basicForm.value.ocrRetriesNumber = 3
    const formItems = store.state.process.design.formItems
    formItems.forEach(e => {
      if (e.type === 'FILE') {
        e.props.required = true
      }

      //关闭远程盖印模式
      if (e.type == 'REMOTE') {
        const options = e.props.options
        options.map(item => {
          if (item.name == '远程盖印') {
            item.isOpen = false
            item.childSelect = []
          }
        })
      }
    })
    store.commit('loadForm', {
      ...store.state.process.design,
      formItems
    })
  } else {
    basicForm.value.ocrType = 2
  }
}
//二维码水印
const changQRcodeType = value => {
  if (value) {
    //判断盖印模式是否仅开启的了远程
    if (state.openModelList.length == 1 && state.openModelList.includes('远程盖印')) {
      return cmsNotice('warning', '远程盖印不支持二维码水印')
    }
    //二维码水印打开关闭OCR，二者存其一
    basicForm.value.ocrType = 2
    //默认选择单文件
    basicForm.value.qrcodePageType = 1
    //开关
    basicForm.value.qrcodeType = 1

    const formItems = store.state.process.design.formItems
    formItems.forEach(e => {
      //文件上传必传
      if (e.type === 'FILE') {
        e.props.required = true
      }
      //关闭远程盖印模式
      if (e.type == 'REMOTE') {
        const options = e.props.options
        options.map(item => {
          if (item.name == '远程盖印') {
            item.isOpen = false
            item.childSelect = []
          }
        })
      }
    })
    store.commit('loadForm', {
      ...store.state.process.design,
      formItems
    })
  } else {
    //关闭二维码水印
    basicForm.value.qrcodeType = 2
    //单文件
    basicForm.value.qrcodePageType = 1
  }
}
//单页或单文件
const changeQrcodePageType = e => {
  console.log(e.target.value)
  const formItems = store.state.process.design.formItems
  formItems.forEach(val => {
    if (val.type === 'REMOTE') {
      //选择单页关闭连续盖印开关
      val.props.options.map(item => {
        if (item.name == '远程盖印') {
          item.isOpen = false
          item.childSelect = []
        }
      })
    }
  })
  if (e.target.value == 2) {
    formItems.forEach(val => {
      if (val.type === 'REMOTE') {
        //选择单页关闭连续盖印开关
        val.props.options.map(item => {
          if (item.name == '连续盖印') {
            item.isOpen = false
            item.childSelect = []
          }
        })
      }
    })
  }
  store.commit('loadForm', {
    ...store.state.process.design,
    formItems
  })
}

// 开关多个地址
const changAddress = value => {
  console.log('打印数据', formState.value.props.showTakeOut)
  basicForm.value.isMultipleAddress = value
  const formItems = store.state.process.design.formItems
  formItems.forEach(e => {
    if (e.type === 'TAKE_OUT') {
      e.props.isMultipleAddress = value
      console.log('eeeee', e)
    }
  })
  console.log('最终的数据', basicForm.value)
  store.commit('loadForm', {
    ...store.state.process.design,
    formItems
  })
}

function checkMate() {
  if (!/^[0-9]+$/.test(basicForm.value.ocrDegree))
    basicForm.value.ocrDegree = basicForm.value.ocrDegree.replace(/\D/g, '')
  if (basicForm.value.ocrDegree > 100) basicForm.value.ocrDegree = 100
  if (basicForm.value.ocrDegree < 0) basicForm.value.ocrDegree = null
}
function checkNode(node) {
  if (!['CONDITIONS', 'CONDITION'].includes(node.type)) {
    // 不是条件分支，进入下一次递归
    if (node.children?.id) checkNode(node.children)
    return
  }

  if (node.type == 'CONDITIONS') {
    // 条件分支父级虚拟节点
    node.branchs.forEach(it => checkNode(it))
  } else if (!node.typeElse) {
    // 有条件的分支节点，且不是默认节点，获取条件信息
    // node.typeElse true 为默认节点
    let hasId = node.props.groups[0].conditions.some(it => it?.id == currentFieldId)
    hasId && conditionsList.push(node.props.groups[0].conditions)
    hasId && cidsList.push(node.props.groups[0].cids)
  }
  if (node.children?.id) checkNode(node.children)
}

const clickCheck = (element, node) => {
  console.log('看看点击勾选的必填项', element, node)
  // console.log(formState.value.props.required);
  // TODO：全局记录外带必填是否开启状态
  console.log('当前控件', formState.value.type)

  conditionsList = []
  cidsList = []
  if (!formState.value.props.required) {
    formState.value.props.required = true
    if (formState.value.type === 'TAKE_OUT') basicForm.value.takeOut = 1
  } else {
    // OCR开启 默认上传文件控件 必填 不可取消
    if (basicForm.value.ocrType === 1 && formState.value.type === 'FILE') {
      return notification['error']({
        message: '提示',
        description: '请先关闭OCR后取消'
      })
    }
    // 二维码水印开启 上传文件必传
    if (basicForm.value.qrcodeType === 1 && formState.value.type === 'FILE') {
      return notification['error']({
        message: '提示',
        description: '请先关闭二维码水印后取消'
      })
    }

    currentFieldId = element
    checkNode(node)
    currentFieldId = 0

    // console.log(999,conditionsList);
    if (!conditionsList.length) {
      formState.value.props.required = false
      if (formState.value.type === 'TAKE_OUT') basicForm.value.takeOut = 2
      return
    }
    Modal.confirm({
      title: '是否取消必填！',
      width: '420px',
      content: '该组件已被设为分支条件，取消必填后，流程设置中的相关的条件及设置将同步删除',
      centered: true,
      icon: createVNode(ExclamationOutlined),
      onOk: () => {
        conditionsList.forEach((el, ind) => {
          const delIndex = el.findIndex(e => e.id === element)
          if (delIndex !== -1) {
            el.splice(delIndex, 1)
            cidsList[ind].splice(delIndex, 1)
          }
        })
        formState.value.props.required = false
        conditionsList = []
        cidsList = []
      },
      onCancel: () => {
        conditionsList = []
        cidsList = []
      }
    })
  }
}

// 控件名称失焦
const titleBlur = (rules, value) => {
  //操作日志详情回显不用校验
  if (props.allDisabled) return

  // 找条件里是否有相同的控件，有的话改变title
  currentFieldId = formState.value?.id
  checkNode(store.state.process.design.process)
  currentFieldId = 0
  console.log(conditionsList)
  if (conditionsList.length) {
    for (let item of conditionsList) {
      let repeact = item && item.find(el => el.id === formState.value.id)
      repeact && (repeact.title = value)
    }
  }
  if (!value) {
    return Promise.reject('控件名称为空，请填写控件名称')
  } else {
    // console.log('77777',value);
    const formItems = store.state.process.design.formItems
    let titleList = []
    formItems.forEach(item => {
      // console.log(item.title);
      if (item.controlName === 'SealInfo' || item.controlName === 'TAKE_OUT') {
        // 印章控件组
        item.props.items.forEach(el => {
          titleList.push(el.title)
        })
      }
      if (item.controlName === 'DateTimeRange') {
        titleList.push(item.props.durationTitle)
      }
      if (item.controlName === 'SealInfo') return
      titleList.push(item.title)
    })
    let repeact = []
    titleList.forEach(item => {
      if (titleList.indexOf(item) !== titleList.lastIndexOf(item)) {
        // console.log(item);
        repeact.push(item)
      }
    })
    const obj = repeact.find(el => el === value)
    // console.log('obj',obj);
    if (obj) {
      return Promise.reject('控件名称不可重复')
    } else {
      return Promise.resolve()
    }
  }
}

const rules = {
  title: {
    required: true,
    trigger: 'blur',
    validator: titleBlur
  },
  currencyType: {
    required: true,
    message: '请选择币种',
    trigger: 'blur'
  },
  format: {
    required: true,
    message: '请选择日期类型',
    trigger: 'blur'
  }
}
const store = useStore()
const basicForm = computed({
  get() {
    return store.state.process.basic
  },
  set(val) {
    store.state.process.basic = val
  }
})
const formState = computed({
  get() {
    return store.state.process.selectFormItem
  },
  set(val) {
    store.state.process.selectFormItem = val
  }
})

const disabledQrcodeType = reactive({
  disabledPage: false
})
watch(
  () => store.state.process.design.formItems,
  () => {
    const formItems = store.state.process.design.formItems
    if (formItems) {
      //当前开启的盖印模式列表
      let openModelList = []

      formItems.map(item => {
        if (item.type == 'REMOTE') {
          const options = item.props.options
          openModelList = options.filter(item => item.isOpen).map(item => item.name)
        }
      })

      //针对盖印方式仅开启了连续用印模式时，二维码水印--单页盖印模式&&ocr单页拍摄置灰不可点击
      if (openModelList.includes('连续盖印') && openModelList.length == 1) {
        disabledQrcodeType.disabledPage = true
      } else {
        disabledQrcodeType.disabledPage = false
      }

      //记录下打开的开关
      state.openModelList = openModelList
    }
  },
  {
    deep: true,
    immediate: true
  }
)
const formRef = ref()
const options = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }]
</script>

<style lang="less" scoped>
.form-config-wrapper {
  padding: 24px;
}
.ant-input-group-addon {
  background: none;
}
:deep(.ant-checkbox-disabled + span) {
  color: rgba(0, 0, 0, 0.85);
}
.ocr-container {
  margin-top: -8px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .match-ratio {
    margin: 16px 0;
  }
  .explain {
    margin: 4px 0 16px 0;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #00000040;
    line-height: 17px;
  }
  .margintop_24 {
    margin-top: 24px;
  }
}
</style>
